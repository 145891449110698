import React from "react";
// Estilos
import Container from "./styled";
// Utils
import useWindowDimensions from "../../../utils/useWindowDimensions";

export default function Ssn() {
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      <div id="ssn-container">
        <div id="data-logo-ssn-container">
          {/* Logo Container */}
          <div id="logo-ssn-container">
            {/* Logo */}
            <a
              href="https://www.gyssrl.com.ar/webroot/footer/certificadoapt.pdf"
              target="_blank"
              id="logo-SSN"
            >
              <img alt="Logo SSN" src="/logos/otros/ssn.png"></img>
            </a>
          </div>
          <p>N° de inscripción SSN: 0387</p>
          {width <= 1200 ? (
            <></>
          ) : (
            <p>Dept. de orientación y asistencia al asegurado</p>
          )}

          <a>0800-666-8400</a>
          <a
            href="https://www.argentina.gob.ar/superintendencia-de-seguros"
            target="_blank"
          >
            www.argentina.gob.ar/snn
          </a>
        </div>
        <p id="texto-legal">
          La entidad aseguradora dispone de un Servicio de Atención al Asegurado
          que atenderá las consultas y reclamos que presenten los tomadores de
          seguros, asegurados, beneficiarios y/o derechohabientes.<br></br>
          <br></br>En caso de que existiera un reclamo ante la entidad
          aseguradora y que el mismo no haya sido resuelto o haya sido
          desestimado, total o parcialmente, o que haya sido denegada su
          admisión, podrá comunicarse con la Superintendencia de Seguros de la
          Nación por teléfono al 0800-666-8400, correo electrónico a
          consultas@ssn.gob.ar o formulario disponible en la página
          argentina.gob.ar/ssn
        </p>
        <div id="contacto-ssn-container">
          <p>El Servicio de Atención al Asegurado está integrado por:</p>
          <p>
            Leandro Mereque (Responsable)
            <br />
            emision@seguroweb.com.ar
            <br />
            0810-220-2373
          </p>
        </div>
      </div>
      {/* Texto Copyright */}
      <div id="copyright-container">
        <p>
          © SeguroWeb 2023. Todos los derechos reservados.
          <br />
          SeguroWeb es marca de Gestión y Servicios S.R.L.
        </p>
        {/* Logo */}
        <a href="https://www.gys.com.ar/" target="_blank">
          <img alt="Logo de Gestión y Servicio" src="/logos/gys/gys.svg"></img>
        </a>
      </div>
    </Container>
  );
}

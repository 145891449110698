import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { BsWhatsapp } from "react-icons/bs";
import { SiMaildotru } from "react-icons/si";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";

export default function CardNumeros({ tipo }) {
  return (
    <Container tipo={tipo}>
      {/* Titulo */}
      {tipo == "siniestros" ? (
        <h3>
          ¿Tuviste un <span>siniestro</span>?
        </h3>
      ) : (
        <h3>
          <span>Emision</span>
        </h3>
      )}
      {/* Texto */}
      {tipo == "siniestros" ? (
        <p>Denunciá tu siniestro hasta 72hs del hecho.</p>
      ) : (
        <p>Todos los días hábiles de 9 a 18hs.</p>
      )}
      {/* Botones WhatsApp */}
      {tipo == "siniestros" ? (
                <div className="botones-whatsapp botones-whatsapp-siniestros">
                <a>
                  <span id="icono-telefono">
                    <BiPhoneCall />
                  </span>
                  0810-220-2373
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroWeb!%20Ingresé%20desde%20la%20web."
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  11-2669-8544
                  
                </a>
              </div>
      ) : (
        <div className="botones-whatsapp">
          {/* Botón WhatsApp 1 */}
          <a
            id="wppCanal3Frist"
            href="https://api.whatsapp.com/send?phone=541128061469&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroWeb!%20Ingresé%20desde%20la%20web."
            target="_blank"
          >
            <span>
              <BsWhatsapp />
            </span>
            11-2806-1469
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroWeb!%20Ingresé%20desde%20la%20web."
            target="_blank"
          >
            <span>
              <BsWhatsapp />
            </span>
            11-2669-8544
          </a>
        </div>
      )}
      {/* Botón Mail */}
      {tipo == "siniestros" ? 
        <Link
          className="boton-siniestros"
          to="/siniestros"
          smooth={true}
          spy={true}
          duration={1200}
          offset={-100}
          >
          Denunciar siniestro{" "}
        </Link>

      : 
        <a id="boton-mail" href="mailto:siniestros@seguroweb.com.ar">
          <span>
            <SiMaildotru />
          </span>
          emision@seguroweb.com.ar
        </a>
      }
    </Container>
  );
}

        {/*

        <div id="botones-whatsapp">
          <a>
            <span id="icono-telefono">
              <BiPhoneCall />
            </span>
            0810-220-2373
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=541162096951&text=%C2%A1Hola,%20necesito%20atención%20de%20siniestros%20de%20SeguroWeb!%20Ingresé%20desde%20la%20web."
            target="_blank"
          >
            <span>
              <BsWhatsapp />
            </span>
            11-2669-8544
          </a>
        </div>

        <a id="boton-mail" href="mailto:siniestros@seguroweb.com.ar">
          <span>
            <SiMaildotru />
          </span>
          siniestros@seguroweb.com.ar
        </a>
        */}
import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";

class Pregunta extends React.Component {
  render() {
    return (
      <Container>
        <button
          className={this.props.isActive ? "header-abierto" : "header-cerrado"}
          onClick={this.props.clickFunction}
        >
          <h4>{this.props.title}</h4>
          <img
            id="buttonIcon"
            alt={this.props.isActive ? "Cerrar" : "Abrir"}
            src="/home/faqs/arrow.svg"
          ></img>
        </button>
        <Collapse isOpened={this.props.isActive}>
          <div className="texto">
            <p>{this.props.children}</p>
          </div>
        </Collapse>
      </Container>
    );
  }
}

export default Pregunta;

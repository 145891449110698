import Container from "./styled";
import FormSection from "../../FormSection";
import { BiShield, BiFile, BiUser } from "react-icons/bi";
import InputBasic from "../../InputBasic";
import Button from "../../Button";
import InputFile from "../../InputFile";
import InputSelect from "../../InputSelect";
import InputTextarea from "../../InputTextarea"
import InputFileMultiple from "../../InputFileMultiple";
const SiniestroForm = ({ formValues }) => {
  const {
    inputValues,
    handleChange,
    handleChangeExternal,
    handleValidation,
    formErrors,
    provincias,
    localidades,
    tiposRiesgos,
    loading,
    productos
  } = formValues;

  return (
    <Container>
      <form>
      <FormSection
          title="Datos personales"
          description="Contanos cómo podemos ponernos en contacto con vos"
          icon={<BiUser />}
        />
        <InputBasic
          label="Email"
          type="email"
          id="input-email"
          name="email"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.email}
          className={formErrors.email == null ? "" : "input-error"}
          error={formErrors.email}
          placeholder={"tuemail@gmail.com"}
        />
        <InputBasic
          label="C. Área"
          type="number"
          id="input-codigo-area"
          name="codigo_area"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.codigo_area}
          className={formErrors.codigo_area == null ? "" : "input-error"}
          error={formErrors.codigo_area}
          placeholder={"011"}
        />
        <InputBasic
          label="Teléfono"
          type="number"
          id="input-telefono"
          name="telefono"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.telefono}
          className={formErrors.telefono == null ? "" : "input-error"}
          error={formErrors.telefono}
          placeholder={"52632373"}
        />
        <FormSection
          title="Información sobre el hecho ocurrido"
          description="Contanos que pasó el día del siniestro"
          icon={<BiShield />}
        />
        {/* Input Nombre */}
        <InputSelect
          label="Producto"
          id="tipo"
          name="producto"
          value={inputValues.producto}
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          className={formErrors.producto == null ? "" : "input-error"}
          placeholder="Producto"
          options={productos}
          error={formErrors.producto}
        />
        {/* Input Nombre */}
        <InputSelect
          label="Tipo de riesgo"
          id="tipo"
          name="tipo"
          value={inputValues.tipo}
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          className={formErrors.tipo == null ? "" : "input-error"}
          placeholder="Tipo de riesgo"
          options={tiposRiesgos}
          error={formErrors.tipo}
        />
        {/* Input Nombre */}
        <InputSelect
          label="Provincia"
          id="provincia"
          name="provincia"
          value={inputValues.provincia}
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          className={formErrors.provincia == null ? "" : "input-error"}
          placeholder="Provincia"
          options={provincias}
          error={formErrors.provincia}
        />
        {/* Input Nombre */}
        <InputSelect
          label="Localidad"
          id="localidad"
          name="localidad"
          value={inputValues.localidad}
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          className={formErrors.localidad == null ? "" : "input-error"}
          placeholder="Localidad"
          options={localidades}
          error={formErrors.localidad}
        />
        <InputBasic
          label="Fecha"
          type="date"
          id="input-fecha"
          name="fecha"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.fecha}
          className={formErrors.fecha == null ? "" : "input-error"}
          error={formErrors.fecha}
        />
        <InputBasic
          label="Hora"
          type="time"
          id="input-hora"
          name="hora"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.hora}
          className={formErrors.hora == null ? "" : "input-error"}
          placeholder="07:27"
          error={formErrors.hora}
        />
        {/* Input Nombre */}
        <InputBasic
          type="text"
          label="Calle"
          id="input-calle"
          name="calle"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.calle}
          className={formErrors.calle == null ? "" : "input-error"}
          placeholder="Avenida Rivadavia"
          error={formErrors.calle}
        />
        <InputBasic
          label="Altura"
          type="number"
          id="input-altura"
          name="altura"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.altura}
          className={formErrors.altura == null ? "" : "input-error"}
          placeholder="1942"
          error={formErrors.altura}
        />

        <FormSection
          title="Reseña"
          description="Contanos brevemente lo sucedido"
          icon={<BiShield />}
        />
        <InputTextarea
          label="Reseña"
          type="text"
          id="input-descripcion"
          name="descripcion"
          onChange={handleChange}
          onFocus={handleChange}
          onBlur={handleChange}
          value={inputValues.descripcion}
          className={formErrors.descripcion == null ? "" : "input-error"}
          placeholder="Breve reseña de lo sucedido"
          error={formErrors.descripcion}
          fullWidth={true}
          maxLength={1000}
        />
        <FormSection
          title="Documentación (opcional)"
          description="Si tenes foto de tu DNI, de la denuncia policial (si corresponde) o la foto del bien siniestrado, compartirnos los archivos para facilitar el proceso de la denuncia."
          icon={<BiFile />}
        />

        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.archivo_foto_dni}
          texto="Foto del DNI"
          nombre="archivo_foto_dni"
          label="Foto del DNI"
          id="input-foto-dni"
          tituloInfo="Solo el frente"
          requisitos="Solo necesitamos el frente de tu DNI para validar tu identidad."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/EjemploBiciConFecha.svg"
          tituloModal="Foto de bicicleta con fecha actual"
          textoModal="Necesitamos una fotografía de la bicicleta junto con una hoja adherida que muestre claramente la fecha actual en la que se envía la cotización. Utiliza la imagen de ejemplo como guía para la presentación."
          error={formErrors.archivo_foto_dni}
          hideRequeriments={true}
          type="image/*"
        ></InputFile>
        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.archivo_denuncia_policial}
          texto="Denuncia policial"
          nombre="archivo_denuncia_policial"
          label="Denuncia policial"
          id="input-archivo-denuncia-policia"
          tituloInfo="¿Tenés más de un archivo?"
          requisitos={[
            "Si tenes más de un archivo, unificalos en un solo archivo PDF.",
            <a href="https://www.ilovepdf.com/es/unir_pdf" target="_blank">
              Unir ahora
            </a>,
          ]}
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/accesorios.webp"
          tituloModal="Factura de accesorios"
          textoModal={
            <p className="texto-container">
              Necesitamos las facturas de todos los accesorios en formato PDF.
              En caso de tener más de una factura, puedes unificarlas a través
              del siguiente enlace.
              <a
                href="https://www.ilovepdf.com/es/unir_pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Click Aquí
              </a>
              .
            </p>
          }
          error={formErrors.archivo_denuncia_policial}
          hideRequeriments={true}
          type="application/pdf"
        ></InputFile>
        <InputFile
          handleChangeExternal={handleChangeExternal}
          archivo={inputValues.archivo_foto_bien_siniestrado}
          texto="Foto del bien siniestrado"
          nombre="archivo_foto_bien_siniestrado"
          label="Foto del bien siniestrado"
          id="archivo_foto_bien_siniestrado"
          tituloInfo="Estado actual del bien"
          requisitos="Agregá una foto del bien luego de ocurrido el siniestro."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/EjemploBiciConFecha.svg"
          tituloModal="Foto de bicicleta con fecha actual"
          textoModal="Necesitamos una fotografía de la bicicleta junto con una hoja adherida que muestre claramente la fecha actual en la que se envía la cotización. Utiliza la imagen de ejemplo como guía para la presentación."
          error={formErrors.archivo_foto_bien_siniestrado}
          hideRequeriments={true}
          type="image/*"
        ></InputFile>

        <FormSection
          title="Otros archivos (opcional)"
          description="En el caso de que lo consideres necesario, adjuntá archivos extra para la documentación del siniestro. Máximo 5 archivos."
          icon={<BiFile />}
        />
        <InputFileMultiple
          handleChangeExternal={handleChangeExternal}
          archivos={inputValues.archivo_extras}
          texto="Otros archivos"
          nombre="archivo_extras"
          label="Otros archivos"
          id="archivo_extras"
          tituloInfo="Estado actual del bien"
          requisitos="Agregá una foto del bien luego de ocurrido el siniestro."
          peso="Hasta 2mb máx."
          imagenModal="/images/pasos/contrata/ejemplos/EjemploBiciConFecha.svg"
          tituloModal="Foto de bicicleta con fecha actual"
          textoModal="Necesitamos una fotografía de la bicicleta junto con una hoja adherida que muestre claramente la fecha actual en la que se envía la cotización. Utiliza la imagen de ejemplo como guía para la presentación."
          error={formErrors.archivo_extras}
          hideRequeriments={true}
          type="image/*"
        ></InputFileMultiple>

        <Button type="submit" onClick={handleValidation} disabled={loading}>
          {loading ? "Denunciando siniestro..." : "Denunciar siniestro"}
        </Button>
      </form>
    </Container>
  );
};

export default SiniestroForm;

import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { BsUpload, BsFiletypePdf } from "react-icons/bs";
// Custom Hooks
import { useFile } from "../../../../hooks/useFile";

export default function InputFile({ archivo, handleChangeExternal, error }) {
  const { onFileUpload, deleteFile } = useFile(handleChangeExternal);

  return (
    <Container>
      <div id="input-file-container">
        {archivo ? (
          <></>
        ) : (
          <div>
            {/* Label / Botón */}
            <label for="file-input">
              <span>
                <BsUpload></BsUpload>
              </span>
              <p>Subí tu CV</p>
              <p>.pdf</p>
            </label>
            {/* Input */}
            <input
              type="file"
              name="cv"
              accept="application/pdf"
              id="file-input"
              onChange={(e) => onFileUpload(e)}
            ></input>
          </div>
        )}
        {archivo ? (
          <div id="archivo-container">
            <div id="boton-container">
              <a
                id="archivo-boton"
                href={URL.createObjectURL(archivo.image)}
                target="_blank"
              >
                <BsFiletypePdf></BsFiletypePdf>
              </a>
            </div>
            <div id="nombre-container">
              <p>{archivo.name}</p>
              <span id="borrar-boton" onClick={() => deleteFile("fileUpload")}>
                Eliminar
              </span>
            </div>
          </div>
        ) : null}
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
}

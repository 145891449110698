import React, { useEffect } from "react";
// Librerías
import styled from "styled-components";
import { motion } from "framer-motion";
// Components
import Switch from "../components/forma-parte/switch";
import { useSEO } from "../hooks/useSEO";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--celeste90);

  // Motion Div
  .motion-div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }
`;

export default function FormaPartePage() {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

   // SEO
   useSEO({
    title: "Forma parte",  
    description: "¿Tenés ganas de trabajar con nosotros? ¡Formá parte de nuestra empresa!"
  })

  return (
    <Container>
      <motion.div
        className="motion-div"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1, transition: { duration: 0.3 } }}
      >
        {/* Formulario */}
        <Switch></Switch>
      </motion.div>
    </Container>
  );
}

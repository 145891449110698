import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { Link as Boton } from "react-router-dom";
// Utils
import { goToTop } from "../../../utils/scrollToTop";

export default function Aside() {
  return (
    <Container>
      {/* Subtitulo */}
      <p>¿Te arrepentiste?</p>
      {/* Texto */}
      <p id="plazos-texto">
        Si aún no pasaron 10 días de la contratación de tu seguro, te pedimos que no efectues la baja y envies un arrepentimiento de compra.
      </p>
      {/* Mas Información */}
      <div>
        {/* Subtitulo */}
        <p>Más información</p>
        {/* Botón */}
        <a
          target="_blank"
          href="https://www.argentina.gob.ar/justicia/derechofacil/leysimple/boton-arrepentimiento#:~:text=%C2%BFQu%C3%A9%20es%20el%20bot%C3%B3n%20de,inicio%20del%20sitio%20de%20Internet."
        >
          argentina.gob.ar/justicia
        </a>
      </div>
      {/* Ver más */}
      <div>
        {/* Subtitulo */}
        <p>Ver más</p>
        {/* Botón */}
        <Boton to="/arrepentimiento" onClick={goToTop}>
          Arrpentimiento de compra
        </Boton>
      </div>
    </Container>
  );
}

import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;

  // Botón Contratá Container
  #boton-contrata-container {
    width: 100%;
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  // Botón Contratá
  #boton-contrata {
    width: auto;
    margin: 50px 0px 20px 0px;
    padding: 14px 26px 14px 26px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--azul100);
    border-radius: 8px;
    border: 0;
    text-decoration: none;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 154.7%;
    text-align: center;
    color: var(--blanco);
    transition: 0.2s all;

    // Ícono
    span {
      margin: 1px 0px 0px 8px;
      font-size: 20px;
      transition: 0.2s all;
    }

    :hover {
      background-color: var(--azul40);

      span {
        transform: translate(8px, 0px);
      }
    }
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 1000px;
    }

    // Formulario
    form {
      max-width: 1000px;
    }

    // Input Container y Select Container
    .input-container,
    .select-container {
      width: 238.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Telefono
    #input-telefono-container {
      width: 134.5px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 40px 0px 0px 0px;
    }

    #max-width-coberturas-container {
      max-width: 680px;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 290px;
      margin: 40px 0px 0px 0px;
    }

    // Checks Container
    .checks-container {
      width: 150px;
    }

    // Checks Container Span
    .checks-container span {
      display: none;
    }

    // Formulario
    form {
      max-width: 680px;
    }

    // Input Container, Select Container, Input Código Area, Input Telefono y Input Vencimiento
    .input-container,
    .select-container,
    #input-codigo-area-container,
    #input-telefono-container,
    #input-vencimiento-container {
      width: 216.5px;
      max-width: 216.5px;
    }

    // Input Código Postal
    #input-codigo-postal-container,
    #input-color-container {
      margin: 30px 15px 0px 0px;
    }

    // Input CUIT DNI, Input Telefono y Código de Área Container
    #input-cuit-dni-container,
    #input-telefono-container,
    #input-codigo-area-container,
    #input-rodado-container,
    #input-transmision-container,
    #input-vencimiento-container {
      margin: 30px 0px 0px 0px;
    }

    // Datos Tarjeta
    #datos-tarjeta-credito {
      width: 100% !important;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    // Datos Vehiculo Container
    .datos-vehiculo-container {
      width: 100%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    #input-rodado-container {
      width: 232.5px;
      max-width: 232.5px;
      margin: 30px 15px 0px 0px;
    }

    // Input Apellido, Input Código Postal, Input Código Área, Input Banco Emisor, Input Titular, Input CBU
    #input-apellido-container,
    #input-codigo-postal-container,
    #input-codigo-area-container,
    #input-modelo-container,
    #input-banco-emisor-container,
    #input-titular-container,
    #input-cbu-container,
    #input-color-container {
      margin: 30px 0px 0px 0px;
    }

    #input-apellido-container,
    #input-banco-emisor-container,
    #input-titular-container {
      text-transform: capitalize !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 380px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Formulario
    form {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: calc(50% - 7.5px) !important;
      max-width: calc(50% - 7.5px) !important;
    }

    #datos-tarjeta-credito {
      width: 100%;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 0px 0px 0px;
      border-right: 0px;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    // Coberturas Container
    .coberturas-container {
      max-width: 115px;
    }

    // Formulario
    form {
      max-width: 85%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container,
    .select-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    #input-cuit-dni-container,
    #input-codigo-area-container,
    #input-vencimiento-container,
    #input-telefono-container {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export default Container;

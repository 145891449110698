import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import ReCAPTCHA from "react-google-recaptcha";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useLoading } from "../../../hooks/useLoading";
// Utils
import { arrepentimientoForm } from "../../../utils/formInitialValues";
import { arrepentimientoReglas } from "../../../utils/formRules";
import { arrepentimientoMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";
// Componentes
import Aside from "../aside";

export default function Formulario({ pasoSiguiente }) {
  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors
    } = useForm(handleSubmit, arrepentimientoReglas, arrepentimientoForm);
  const { loading, initiateLoading } = useLoading();

  async function handleSubmit() {
    try {
      initiateLoading();
      await sendFormMail(inputValues, pasoSiguiente, arrepentimientoMail);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Formulario Container */}
        <div id="formulario-container">
          <div id="titulo-container">
            {/* Botón Inicio */}
            <a id="boton-inicio" href="/">
              <span>
                <HiOutlineArrowLongLeft />
              </span>{" "}
              Inicio
            </a>
            {/* Título */}
            <h3>
              ¿Te <span>arrepentiste</span> de la contratación de tu seguro?
            </h3>
            {/* Tag */}
            <p id="tag">Resolución 424/2020</p>
            {/* Texto */}
            <p id="texto">
              La resolución 424/2020 de la Secretaría de Comercio Interior,
              otorgá el derecho de que puedas revocar la contratación del mismo
              hasta 10 días desde la solicitud de contratación del mismo.
            </p>
            <p id="texto">
              Si te arrepentiste de tu contratación de SeguroWeb, te pedimos que
              completes el siguiente formulario con tus datos. Dentro de las
              próximas 24hs hábiles nos vamos a comunicar con vos para otorgarte
              tu número de solicitud generado y avanzar con el proceso.
            </p>
          </div>
          {/* Formulario */}
          <form>
            {/* Input Nombre */}
            <div className="input-container" id="input-nombre-container">
              <input
                type="text"
                id="input-nombre"
                name="nombre"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.nombre}
                className={formErrors.nombre == null ? "" : "input-error"}
                placeholder="Nombre"
              ></input>
            </div>
            {/* Input Apellido */}
            <div className="input-container" id="input-apellido-container">
              <input
                type="text"
                id="input-apellido"
                name="apellido"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.apellido}
                className={formErrors.apellido == null ? "" : "input-error"}
                placeholder="Apellido"
              ></input>
            </div>
            {/* Input Email */}
            <div className="input-container" id="input-email-container">
              <input
                type="email"
                id="input-email"
                name="email"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.email}
                className={formErrors.email == null ? "" : "input-error"}
                placeholder="Email"
              ></input>
            </div>
            {/* Input DNI */}
            <div className="input-container" id="input-dni-container">
              <input
                type="number"
                id="input-dni"
                name="dni"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.dni}
                className={formErrors.dni == null ? "" : "input-error"}
                placeholder="D.N.I."
              ></input>
            </div>
            {/* Input Telefono */}
            <div className="input-container" id="input-telefono-container">
              <input
                type="number"
                id="input-telefono"
                name="telefono"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.telefono}
                className={formErrors.telefono == null ? "" : "input-error"}
                placeholder="Teléfono"
              ></input>
            </div>
            {/* Input Número de póliza */}
            <div className="input-container" id="input-poliza-container">
              <input
                type="number"
                id="input-poliza"
                name="poliza"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.poliza}
                className={formErrors.poliza == null ? "" : "input-error"}
                placeholder="N° de póliza (Si fue dado)"
              ></input>
            </div>
            {/* Textarea Mensaje */}
            <div className="input-container" id="textarea-mensaje-container">
              <textarea
                type="text"
                id="input-mensaje"
                name="mensaje"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.mensaje ? inputValues.mensaje : ""}
                className={formErrors.mensaje == null ? "" : "input-error"}
                placeholder="Ingresá el motivo de la baja"
              ></textarea>
            </div>
            {/* Captcha Container 
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={(value) => handleChangeExternal("captcha", value)}
              />
            </div>
            */}
            {/* Botón Container */}
            <div id="boton-container">
              {/* Botón */}
              <button
                type="submit"
                onClick={handleValidation}
                disabled={loading}
              >
                {loading ? "Enviando..." : "Enviar"}
                <span>
                  <HiOutlineArrowLongRight />
                </span>
              </button>
            </div>
          </form>
        </div>
        {/* Separación */}
        <div id="separacion">
          <hr></hr>
        </div>
        <Aside></Aside>
      </div>
    </Container>
  );
}

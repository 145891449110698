import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Contrataste Container
  .contrataste-container {
    width: 100%;
    max-width: 1270px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 35px 0px 0px 0px;
    position: relative;
    flex-direction: column;
    align-items: center;
  }

  // Animación Container
  #animacion-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    opacity: 0%;
    animation-name: animacion-completa;
    animation-duration: 1.8s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  @keyframes animacion-completa {
    from {
      opacity: 100%;
    }
    95% {
      opacity: 100%;
    }
    to {
      opacity: 0%;
    }
  }

  // Animación Container Span
  #animacion-container span {
    width: 600px;
    height: 600px;
    position: absolute;
    border-radius: 40% 40% 100% 100%;
    background-color: #56c662;
    animation-name: circulo-verde;
    animation-duration: 2.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-in;
    z-index: 100;
  }

  @keyframes circulo-verde {
    from {
      width: 0px;
      height: 0px;
    }
    70% {
      width: 0px;
      height: 0px;
    }
    to {
      width: 1800px;
      height: 1800px;
    }
  }

  // Titulo
  h3 {
    width: 100%;
    margin: 0px 0px 0px 0px;
    text-align: center;
  }

  // Seguro Container
  #seguro-container {
    width: min-content;
    margin: 64px 0px 0px 0px;
    padding: 24px 32px 24px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    border-radius: 12px 12px 12px 12px;
    background-color: var(--blanco);
    border: 1px solid var(--grisClaro);
    box-shadow: 0px 5px 30px var(--sombraNaranja);
  }

  // Seguro Container Imagen
  #seguro-container img {
    width: 100%;
    min-width: 135px;
    max-width: 135px;
    margin: 0px 0px 0px 0px;
    object-fit: contain;
  }

  // Seguro Container Texto
  #seguro-container p {
    width: 100%;
    margin: 24px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    font-size: 13px;
    color: var(--naranja100);
    font-weight: 600;
    line-height: 24px;
  }

  // Seguro Container Texto
  #seguro-container p:nth-child(3) {
    width: 100%;
    margin: 6px 0px 0px 0px !important;
    color: var(--negro);
    font-weight: 500;
  }

  /*
  // Texto Contacto
  #texto-contacto {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: var(--negro);
    font-size: 22px;
    font-weight: 500;
    text-align: center !important;
  }
  */

  #texto-contacto {
    margin: 0px;
    display: flex;
    -webkit-box-pack: center;
    place-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  .texto-agradecimiento {
    display: flex;
    flex-direction: column;
  }

  .texto-agradecimiento-area {
    font-size: 14px;
    color: var(--azul100);
    font-weight: 500;
    font-family: "DM Sans";
  }

  .texto-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  // Texto Contacto Ícono
  #texto-contacto span {
    margin: 14px 7px 0px 0px;
    font-size: 22px;
    font-weight: 600;
  }

  // Datos preparados
  .datos-preparados {
    width: 100%;
    margin: 48px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;

    h4 {
      width: 100%;
      margin: 0px 0px 24px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;
      flex-wrap: wrap;
      line-height: 28px;

      strong {
        font-size: 17px !important;
        color: var(--verde50);
        margin: 5px 12px 0px 0px !important;
      }

      span {
        color: var(--naranja100) !important;
        margin: 0px 0px 0px 4px !important;
      }
    }

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start;
      margin: 7px 0px 0px 0px;
    }

    div p {
      width: fit-content;
      margin: 0px 0px 0px 0px !important;
      font-size: 13.5px !important;
      line-height: 25px !important;
      text-align: center !important;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }
  }

  // Canal Container
  .canal {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Canal Botones
  .canal a {
    margin: 2px 8px 0px 8px;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-decoration: none;
    color: var(--verde50);
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 192.7%;
    opacity: 1;
    cursor: pointer;
    transition: 0.2s;
  }

  // Canal Botones Ícono
  .canal a span {
    margin: 6px 6px 0px 0px;
    color: var(--verde50);
    font-size: 16px;
    opacity: 1;
    transition: 0.2s;
  }

  // Canal Botones Hover
  .canal a:hover {
    opacity: 0.7;
    span {
      opacity: 0.8;
    }
  }


  // Botón Mail
  #boton-mail,
  #boton-telefono {
    color: var(--lila90);
  }

  // Botón Mail Ícono
  #boton-mail span,
  #boton-telefono span {
    margin: 8px 6px 0px 0px;
    font-size: 19px;
    color: var(--lila90);
  }

  // Canal Line
  .canal p {
    margin: 0px 0px 0px 0px;
    color: #efefef;
    font-weight: light;
    font-size: 24px;
    text-align: center;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 480px;
    }

    // Canal Container
    .canal {
      flex-wrap: wrap;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 350px;
    }
          // Canal Botones
  .canal a {
    width: 100%;
    margin: 8px 0px 0px 0px;
  }


      // Canal Line
  .canal p {
    display: none;
  }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Contrataste Container
    .contrataste-container {
      max-width: 85%;
    }
  }
`;

export default Container;

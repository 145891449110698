import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 48px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  background-color: var(--blanco);
  z-index: 98;

  // Max Width Container
  #max-width-container {
    width: 1188px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
  }

  // Left Zone
  #left-zone {
    width: 782.6px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  // Right Zone
  #right-zone {
    width: 377.3px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
  }

  

  // Cards Container
  .carousel {
    width: 100%;
    height: 168px;
    padding: 0px 0px 0px 0px;
    margin: 26px 0px 0px 0px;
    background-color: var(--blanco);
    border-radius: 10px;
    border: 1px solid var(--gris80);
  }

  .carousel-row {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel-track {
    width: 100%;
    justify-content: space-between !important;
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {

    .carousel {
      margin: 26px 0px 0px 0px;
    }

    // Max Width Container
    #max-width-container {
      width: 1000px;
    }

    // Left Zone
    #left-zone {
      width: 648px;
    }

    // Right Zone
    #right-zone {
      height: 648px;
      width: 324px;
      margin: 0px 0px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }

    // Left Zone
    #left-zone {
      width: 100%;
    }

    // Right Zone
    #right-zone {
      height: auto;
      width: 100%;
      justify-content: space-between;
      
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 480px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Max Width Container
    #max-width-container {
      width: 400px;
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Max Width Container
    #max-width-container {
      max-width: 85%;
    }
  }
`;

export default Container;

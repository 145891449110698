import * as React from "react";
// Estilos
import Container from "./styled";
// Componentes
import Asistencia from "./asistencia";
import Digitalizacion from "./digitalizacion";

export default function Beneficios() {
  return (
    <Container name="beneficios">
      <Asistencia></Asistencia>
      <Digitalizacion></Digitalizacion>
    </Container>
  );
}

import React, { useRef } from "react";
// Librerías y constants
import { BrowserRouter as Router } from "react-router-dom";
import Headroom from "react-headroom";
import AnimatedRoutes from "./constants/animatedRoutes";
import GlobalStyle from "./constants/globalStyles";
// Componentes
import Header from "./components/header";
import Footer from "./components/footer";
import { SocketProvider } from "./context/SocketProvider";

function App() {

  return (
    <div className="App">
      <SocketProvider>
      <Router>
        {/* Header */}
        <Headroom>
          <Header></Header>
        </Headroom>
        {/* Routes animadas */}
        <AnimatedRoutes></AnimatedRoutes>
        {/* Footer */}
        <Footer></Footer>
      </Router>
      </SocketProvider>
      {/* Estilos globales */}
      <GlobalStyle></GlobalStyle>
    </div>
  );
}

export default App;

import React from "react";
// Estilos
import Container from "./styled";

export default function Titulo() {
  return (
    <Container>
      {/* Título Max Width Container */}
      <section id="titulo-max-width-container">
        {/* Tag */}
        <p id="tag">Conocenos</p>
        {/* Título */}
        <h3>
          Estamos para <span>vos</span>
        </h3>
        {/* Texto */}
        <p id="texto">
          Conocé sobre nosotros, quienes somos y que hacemos para darte el mejor
          servicio
        </p>
      </section>
    </Container>
  );
}

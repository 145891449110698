import * as React from "react";
// Estilos
import Container from "./styled";

export default function Digitalizacion() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Card Container */}
        <div id="card-container">
          <div id="image-container">
            <img src="/home/asistencia.webp"></img>
          </div>
          <div id="data-container">
            {/* Tag Container */}
            <div id="tag-container">
              {/* Tag */}
              <div>
                <p>Asistencia</p>
              </div>
            </div>
            {/* Title */}
            <h3>
              Brindamos asistencia<br></br>
              <span>inmediata</span> y <span>especializada</span>
            </h3>
            <p>
              Queremos que tu experiencia tanto en el proceso de contratación
              como al ser cliente de la empresa, sea la mejor. Nuestro equipo de
              atención en las diferentes áreas está capacitado para brindarte el
              mejor servicio y de la mejor manera.
              <br></br>
              <br></br>
              Contamos con diversos canales de atención para que te comuniques
              de la forma más conveniente.
            </p>
          </div>
          <img
            src="/home/digitalizacion/curva.svg"
            id="curva-image"
            alt="Curva decorativa"
          ></img>
        </div>
      </section>
    </Container>
  );
}

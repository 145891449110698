import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 160px;
  margin: 64px 0px 0px 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--blanco);
  border: 1px solid var(--gris80);
  border-radius: 10px;

  // Estadistica
  .estadistica-container {
    width: calc(377.3px - 56px);
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 28px 0px 28px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    // Número
    h3 {
      width: 100%;
      margin: 6px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      line-height: 23px;
      letter-spacing: -0.015em;
      color: var(--azul100);

      span {
        width: 10px;
        margin: 0px 8px -6px 0px;
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        justify-content: center;
        font-weight: 500;
        font-size: 19px;
        color: var(--negro);
      }
    }

    // Titulo
    p {
      width: 210px;
      margin: 16px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: -0.005em;
      color: var(--verde50);
    }

    // Texto
    p:nth-child(3) {
      margin: 8px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 160%;
      text-align: center;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }
  }

  // Separación
  .separacion {
    width: 1px;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;

    hr {
      width: 1px;
      height: calc(100% - 48px);
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      border: 1px solid #e8f0ff;
      border-top: 0px !important;
      border-bottom: 0px !important;
      border-right: 0px !important;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Estadistica
    .estadistica-container {
      width: calc(332.3px - 57px);
    }
  }

  //1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Estadistica
    .estadistica-container {
      width: calc(339px - 57px);
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 480px;

    // Estadistica
    .estadistica-container {
      width: calc(239.3px - 56px);
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    width: 400px;

    // Max Width Container
    section {
      width: 400px;
      height: 172px;
    }

    // Estadistica
    .estadistica-container {
      width: 100%;

      // Número
      h3 {
        font-size: 24px;

        span {
          font-size: 17px;
        }
      }

      // Titulo
      p {
        width: 70%;
        margin: 12px 0px 0px 0px;
        font-size: 13px;
      }

      p:nth-child(2) {
        width: 100%;
      }
    }
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    width: 85%;
  }

  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
    // Max Width Container
    section {
      width: 332px;
    }
  }

  // 370px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile370}) {
    // Max Width Container
    section {
      width: 270px;
      height: 148px;
    }

    // Estadistica
    .estadistica-container {
      width: calc(100% - 32px);

      // Titulo
      p {
        width: 230px;
      }
    }
  }
`;

export default Container;

import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 126px;
  margin: 26px 0px 2px 0px;
  padding: 20px 20px 20px 20px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  background-color: var(--blanco);
  border-radius: 10px;
  border: 1px solid var(--gris80);
  overflow: hidden;

  // Titulo
  h3 {
    width: 80%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-weight: 500;
    font-size: 17px;
    text-align: left;
    line-height: 145.5%;
    letter-spacing: -0.015em;

    // Span
    span {
      color: var(--azul100);
    }
  }

  // Estrellas Container
  #estrellas-container {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: relative;

    // Estrellas
    img {
      width: 16px;
      margin: 0px 0px 0px 4px;
      user-select: none;
      pointer-events: none;
    }
  }

  #data-container {
    width: 100%;
    margin: 22px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    // Título Container
    div {
      width: auto;
      margin: 0px 0px 0px 0px;
      padding: 0px 40px 0px 0px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      text-align: center;
      user-select: none;
      pointer-events: none;
    }

    h3 {
      width: auto;
      margin: 0px 16px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-weight: 300;
      font-size: 56px;
      color: var(--verde90);
    }

    h4 {
      width: auto;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: left;

      span {
        color: var(--verde90);
      }
    }

    // Texto
    p {
      width: calc(50% - 43px);
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 40px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 170%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
      -webkit-font-smoothing: subpixel-antialiased;
      border-left: 1px solid rgb(232, 240, 255);
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
   
  }
  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
   
    height: auto;
    align-items: center;
    justify-content: space-around;
    padding: 10px;

    h3 {

      font-size: 16px;
      line-height: 15.5%;
    }

    #estrellas-container {

      // Estrellas
      img {
        width: 12px;
      }
    }

    #data-container {

      padding: 8px;
      margin: 8px 0px 0px 0px;

      div {
        max-width: 250px;
        padding: 0px;
      }

      p{
        padding: 0px 0px 0px 8px;
        max-height: 100px;
      }
    }

  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    

    align-items: center;
    justify-content: center;
    gap: 8px;

    h3 {
      font-size: 16px;
      margin: 5px 0px 5px 0px;
      width: 100%;
      text-align: center;
    }

    #data-container {

      justify-content: center;
      padding: 8px;
      margin: 8px 0px 0px 0px;

      div {
        max-width: 250px;
        padding: 0px;
      }

      p{
        display: none;
      }
    }

  }
  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
   
  }


`;

export default Container;

import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  z-index: 100;

  #tooltip {
    font-size: 12px !important;
    margin: 0px 0px 0px 0px !important;
    padding: 6px 12px 6px 12px !important;
    border-radius: 7px !important;
    background-color: #c9cdd5 !important;
    border: 1px solid var(--gris80) !important;
    color: var(--negro) !important;
    font-weight: 500;
  }

  // Header Container
  .header-container-gray,
  .header-container-white {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-bottom: 1px solid #e8f0ff !important;
    background-color: var(--blanco);
    transition: 0.2s all;
  }

  .header-container-gray {
    background: var(--celeste90);
  }

  // Logo Data Container
  #logo-data-container {
    width: 100%;
    max-width: 1140px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }

  // Logo Escudos Container
  #logo-escudos-container {
    width: auto;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }

  // Escudos Container
  #escudos-container {
    width: auto;
    height: 40px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 24px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    border-left: 1.5px solid #e8f0ff;

    a {
      margin: 0px 12px 0px 0px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      transition: 0.2s all;

      img {
        height: 30px;
      }
    }

    a:hover {
      transform: scale(0.94);
    }
  }

  h1 {
    margin: 0px 24px 0px 0px;
    transition: 0.2s all;
  }

  h1 a {
    cursor: pointer;
  }

  h1 a img {
    display: block;
    height: 30px;
  }

  h1:hover {
    transform: scale(0.97);
  }

  #numeros-redes-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }

  .boton-telefono {
    margin: 2px 10px 0px 0px;
    color: #666666;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.015em;
    text-decoration: none;
  }

  #redes {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin: 0px 24px 0px 0px;
  }

  #redes a {
    margin: 5px 0px 0px 16px;
    font-size: 17px;
    color: var(--azul100);
    transition: 0.2s all;
  }

  #redes a:hover {
    color: var(--azul20);
  }

  .botones-container {
    display: flex;
    gap: 10px;
  }

  .boton-siniestros {
    padding: 9px 18px 9px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--azul100);
    border-radius: 7px;
    color: var(--blanco);
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.015em;
    text-decoration: none;
    transition: 0.2s all;

    span {
      height: 100%;
      margin: 0px 6px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 15px;
    }
  }

  #boton-portal {
    padding: 9px 18px 9px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #c9cdd5;
    border-radius: 7px;
    color: var(--blanco);
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.015em;
    text-decoration: none;
    transition: 0.2s all;

    span {
      height: 100%;
      margin: 0px 6px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 15px;
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    #logo-data-container {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    #logo-data-container {
      max-width: 680px;
    }

    #escudos-container {
      display: none;
    }

    h1 {
      margin: 0px 0px 0px 0px;
    }

    #numeros-redes-container {
      width: 80%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    #logo-data-container {
      justify-content: center;
    }

    #numeros-redes-container {
      display: none;
    }
  }
`;

export default Container;

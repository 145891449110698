import React, { useState, useEffect } from "react";
// Estilos
import Container from "./styled";
// Librerías
import ReCAPTCHA from "react-google-recaptcha";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
import { useLoading } from "../../../hooks/useLoading";
// Utils
import { otrosSegurosForm } from "../../../utils/formInitialValues";
import { otrosSegurosReglas } from "../../../utils/formRules";
import { otrosSegurosMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";
// Componentes
import Aside from "../aside";
import { Link } from "react-router-dom";
// Servicios
import { obtenerProductos } from "../../../services/productos";

export default function Formulario({ pasoSiguiente }) {
  // Custom Hooks
  const {
    inputValues,
    handleChange,
    handleValidation,
    formErrors
  } = useForm(handleSubmit, otrosSegurosReglas, otrosSegurosForm);
  const { loading, initiateLoading } = useLoading();
  const [productos, setProductos] = useState([])

  async function handleSubmit() {
    try {
      initiateLoading();
      await sendFormMail(inputValues, pasoSiguiente, otrosSegurosMail);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const getProductos = async () => {
      try {
        const productos = await obtenerProductos()
        setProductos(productos.data)
      } catch (error) {
        console.log(error)
      }
    }
    getProductos()
  }, [])

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Formulario Container */}
        <div id="formulario-container">
          <div id="titulo-container">
            {/* Botón Inicio */}
            <Link id="boton-inicio" to="/">
              <span>
                <HiOutlineArrowLongLeft />
              </span>{" "}
              Inicio
            </Link>
            {/* Título */}
            <h3>
              ¡Conocé todos nuestros <span>otros seguros</span>!
            </h3>
            {/* Tag */}
            <p id="tag">Contactanos</p>
            {/* Texto */}
            <p id="texto">
              Además de todos nuestros seguros prinicipales, contamos con
              seguros para más de otros 40 productos y servicios de otras
              categorías como robo de indentidad, instrumentos musicales,
              tecnología. ¡Y muchos más!.
            </p>
            <p id="texto">
              ¿Qué estás esperando? Revisá nuestras categorias y productos,
              completa el formulario para ponerte en contacto con nosotros y
              asesorarte en la contratación de tu nuevo seguro con SeguroWeb.
            </p>
          </div>
          {/* Formulario */}
          <form>
            {/* Input Nombre */}
            <div className="input-container" id="input-nombre-container">
              <input
                type="text"
                id="input-nombre"
                name="nombre"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.nombre}
                className={formErrors.nombre == null ? "" : "input-error"}
                placeholder="Nombre"
              ></input>
            </div>
            {/* Input Apellido */}
            <div className="input-container" id="input-apellido-container">
              <input
                type="text"
                id="input-apellido"
                name="apellido"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.apellido}
                className={formErrors.apellido == null ? "" : "input-error"}
                placeholder="Apellido"
              ></input>
            </div>
            {/* Input Email */}
            <div className="input-container" id="input-email-container">
              <input
                type="email"
                id="input-email"
                name="email"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.email}
                className={formErrors.email == null ? "" : "input-error"}
                placeholder="Email"
              ></input>
            </div>
            {/* Input Telefono */}
            <div className="input-container" id="input-telefono-container">
              <input
                type="number"
                id="input-telefono"
                name="telefono"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.telefono}
                className={formErrors.telefono == null ? "" : "input-error"}
                placeholder="Teléfono"
              ></input>
            </div>
            {/* Select Provincia*/}
            <div className="select-container" id="select-provincia-container">
              <select
                id="select-provincia"
                name="provincia"
                value={inputValues.provincia}
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                className={formErrors.provincia == null ? "" : "input-error"}
              >
                <option disabled selected hidden>
                  Provincia
                </option>
                <option value="Buenos Aires">Buenos Aires</option>
                <option value="Catamarca">Catamarca</option>
                <option value="Chaco">Chaco</option>
                <option value="Chubut">Chubut</option>
                <option value="Ciudad de Buenos Aires">
                  Ciudad de Buenos Aires
                </option>
                <option value="Córdoba">Córdoba</option>
                <option value="Corrientes">Corrientes</option>
                <option value="Entre Rios">Entre Rios</option>
                <option value="Formosa">Formosa</option>
                <option value="Jujuy">Jujuy</option>
                <option value="La Pampa">La Pampa</option>
                <option value="La Rioja">La Rioja</option>
                <option value="Mendoza">Mendoza</option>
                <option value="Misiones">Misiones</option>
                <option value="Neuquén">Neuquén</option>
                <option value="Rio Negro">Rio Negro</option>
                <option value="Salta">Salta</option>
                <option value="San Juan">San Juan</option>
                <option value="San Luis">San Luis</option>
                <option value="Santa Cruz">Santa Cruz</option>
                <option value="Santa Fe">Santa Fe</option>
                <option value="Santiago del Estero">Santiago del Estero</option>
                <option value="Tierra del Fuego">Tierra del Fuego</option>
                <option value="Tucumán">Tucumán</option>
              </select>
              <div>
                <i></i>
              </div>
            </div>
            {/* Select Producto o servicio */}
            <div className="select-container" id="select-producto-container">
              <select
                id="select-producto"
                name="categoria"
                value={inputValues.categoria}
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                className={formErrors.categoria == null ? "" : "input-error"}
              >
                <option disabled selected hidden>
                  Producto o servicio
                </option>
                {productos.length > 0 && productos.map((producto, index) => {
                  return (
                    <option value={producto.descripcion}>{producto.descripcion}</option>
                  )
                })}
              </select>
              <div>
                <i></i>
              </div>
            </div>
            {/* Captcha Container 
            <div id="captcha-container">
              <ReCAPTCHA
                sitekey="6LfvW5YdAAAAADsIry4KRJS5YkcRb7L20iJbYLzQ"
                size="compact"
                hl="es"
                theme="light"
                onChange={(value) => handleChangeExternal("captcha", value)}
              />
            </div>
            */}
            {/* Botón Container */}
            <div id="boton-container">
              {/* Botón */}
              <button
                type="submit"
                onClick={handleValidation}
                disabled={loading}
              >
                {loading ? "Enviando..." : "Enviar"}
                <span>
                  <HiOutlineArrowLongRight />
                </span>
              </button>
            </div>
          </form>
        </div>
        {/* Separación */}
        <div id="separacion">
          <hr></hr>
        </div>
        <Aside></Aside>
      </div>
    </Container>
  );
}

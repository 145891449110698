import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  height: 796px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  border-bottom: 1px solid var(--gris80);
  z-index: 98;
  // Puntos de fondo
  background: linear-gradient(
        90deg,
        var(--celeste90) calc(22px - 1px),
        transparent 1%
      )
      center / 22px 22px,
    linear-gradient(var(--celeste90) calc(22px - 1px), transparent 1%) center /
      22px 22px,
    #98a5be;
  position: relative;
  overflow: hidden;

  // Max Width Container
  section {
    width: 1188px;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  // Titulos Container
  #titulos-container {
    width: 100%;
    height: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
  }

  // Encabezado Container
  .encabezado-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;

    // Encabezado
    h4 {
      width: auto;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      line-height: 203%;
      letter-spacing: -0.015em;
      color: var(--negro);
    }
  }

  // Titulo Container
  .titulo-container {
    width: 556px;
    margin: 24px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    pointer-events: none;
    user-select: none;

    // Titulo
    h3 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 40px;
      text-align: center;
      line-height: 145.5%;
      letter-spacing: -0.015em;

      // Span
      span {
        color: #02be85;
        font-weight: 400;
      }

      // Strong
      strong {
        color: var(--azul100);
        font-weight: 500;
      }
    }
  }

  // Texto Container
  .texto-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;

    p {
      width: 484px;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 190%;
      text-align: center;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }
  }

  // Botones Conatiner
  #botones-container {
    width: 100%;
    margin: 64px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;

    // Botón
    a {
      width: auto;
      margin: 0px 10px 0px 10px;
      padding: 14px 26px 14px 26px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      background-color: var(--azul100);
      border-radius: 8px;
      border: 0;
      text-decoration: none;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 154.7%;
      text-align: center;
      color: var(--blanco);
      transition: 0.2s all;

      // Ícono
      span {
        margin: 1px 0px 0px 8px;
        font-size: 20px;
        transition: 0.2s all;
      }

      // Hover
      :hover {
        background-color: var(--azul40);

        // Ícono
        span {
          transform: translate(8px, 0px);
        }
      }
    }

    // Segundo Botón
    a:nth-child(2) {
      padding: 16px 26px 16px 26px;
      background-color: var(--verde50);
      color: var(--blanco);

      // Hover
      :hover {
        background-color: var(--verde90);
      }
    }
  }

  // Sombra Container
  #sombra-container {
    width: 1188px;
    height: 80px;
    margin: 0px auto 0px auto;
    padding: 0px 0px 0px 0px;
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    background-color: transparent;
    box-shadow: var(--verde50) 0px -15px 110px;
    opacity: 0.3;
    content: "";
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    // Max Width Container
    section {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    section {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    section {
      width: 100%;
    }

    // Titulo Container
    .titulo-container {
      width: 400px;

      // Titulo
      h3 {
        font-size: 36px;
      }
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
  }

  // 525px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile525}) {
    // Titulo Container
    .titulo-container {
      width: 77%;

      // Titulo
      h3 {
        font-size: 36px;
      }
    }

    // Botones Conatiner
    #botones-container {
      // Segundo Botón
      a:nth-child(2) {
        display: none;
      }
    }
  }

  // 480px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile480}) {
    // Titulo Container
    .titulo-container {
      width: 84%;
    }
  }

  // 440px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile440}) {
    // Titulo Container
    .titulo-container {
      width: 85%;

      // Titulo
      h3 {
        font-size: 34px;
      }
    }
  }

  // 410px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile410}) {
    // Titulo Container
    .titulo-container {
      width: 82%;

      // Titulo
      h3 {
        font-size: 30px;
      }
    }
  }
`;

export default Container;

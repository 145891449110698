export const referidosForm = {
  email:
    localStorage.getItem("email-seguroweb") !== null
      ? localStorage.getItem("email-seguroweb")
      : "",
  emailReferido: "",
  nombre: "",
  captcha: undefined
}

export const arrepentimientoForm = {
  nombre:
    localStorage.getItem("nombre-seguroweb") !== null
      ? localStorage.getItem("nombre-seguroweb")
      : "",
  apellido:
    localStorage.getItem("apellido-seguroweb") !== null
      ? localStorage.getItem("apellido-seguroweb")
      : "",
  email:
    localStorage.getItem("email-seguroweb") !== null
      ? localStorage.getItem("email-seguroweb")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-seguroweb") !== null
      ? localStorage.getItem("telefono-seguroweb")
      : "",
  mensaje: ''
}

export const bajaServicioForm = {
  nombre:
    localStorage.getItem("nombre-seguroweb") !== null
      ? localStorage.getItem("nombre-seguroweb")
      : "",
  apellido:
    localStorage.getItem("apellido-seguroweb") !== null
      ? localStorage.getItem("apellido-seguroweb")
      : "",
  email:
    localStorage.getItem("email-seguroweb") !== null
      ? localStorage.getItem("email-seguroweb")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-seguroweb") !== null
      ? localStorage.getItem("telefono-seguroweb")
      : "",
  mensaje: ''
}

export const contactoForm = {
  nombre:
  localStorage.getItem("nombre-seguroweb") !== null
    ? localStorage.getItem("nombre-seguroweb")
    : "",
  email:
    localStorage.getItem("email-seguroweb") !== null
      ? localStorage.getItem("email-seguroweb")
      : "",
  telefono:
    localStorage.getItem("telefono-seguroweb") !== null
      ? localStorage.getItem("telefono-seguroweb")
      : "",
  asunto: '',
  mensaje: '',
  captcha: undefined
}

export const formaParteForm = {
  nombre:
    localStorage.getItem("nombre-seguroweb") !== null
    ? localStorage.getItem("nombre-seguroweb")
    : "",
  apellido:
    localStorage.getItem("apellido-seguroweb") !== null
      ? localStorage.getItem("apellido-seguroweb")
      : "",
  email:
    localStorage.getItem("email-seguroweb") !== null
      ? localStorage.getItem("email-seguroweb")
      : "",
  puesto: '',
  mensaje: '',
  cv: ''
}

export const otrosSegurosForm = {
  nombre:
    localStorage.getItem("nombre-seguroweb") !== null
    ? localStorage.getItem("nombre-seguroweb")
    : "",
  apellido:
    localStorage.getItem("apellido-seguroweb") !== null
      ? localStorage.getItem("apellido-seguroweb")
      : "",
  email:
    localStorage.getItem("email-seguroweb") !== null
      ? localStorage.getItem("email-seguroweb")
      : "",
  telefono: '',
  provincia: '',
  categoria: ''
}

// *** Red de Bicicleterias *** //
export const crearSiniestroForm = {
  email: '',
  codigo_area: '',
  telefono: '',
  producto: '',
  tipo: '',
  provincia: '',
  localidad: '',
  fecha: '',
  hora: '',
  calle: '',
  altura: '',
  descripcion: '',
  archivo_foto_dni: '',
  archivo_denuncia_policial: '',
  archivo_foto_bien_siniestrado: '',
  archivo_extras: []
}
import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
// Componentes
import Aside from "../aside";
import Animacion from "./animacion";

export default function Confirmacion() {
  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        {/* Confirmación Container */}
        <div id="confirmacion-container">
          {/* Botón Inicio */}
          <a id="boton-inicio" href="/">
            <span>
              <HiOutlineArrowLongLeft />
            </span>{" "}
            Inicio
          </a>
          {/* Título */}
          <h3>
            ¡Recibimos tu <span>arrepentimiento con éxito</span>!
          </h3>
          {/* Texto */}
          <p id="texto">
            Recordá que dentro de las próximas 24hs hábiles nos contactaremos al
            mail otorgado para enviarte tu número de solicitud generado y
            avanzar con el proceso.
          </p>
          {/* Animación */}
          <Animacion></Animacion>
        </div>
        {/* Separación */}
        <div id="separacion">
          <hr></hr>
        </div>
        {/* Aside */}
        <Aside></Aside>
      </div>
    </Container>
  );
}

import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 377.3px;
  margin: 28px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;

  // Card Container
  #card-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    background-color: var(--blanco);
    border-radius: 10px;
    border: 1px solid var(--gris80);

    // Título Texto Container
    #titulo-texto-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;

      // Título
      h4 {
        width: 100%;
        margin: 0px 0px 4.2px 0px;
        font-weight: 600;
        font-size: 18px;
        text-align: left;
        letter-spacing: -0.015em;
        line-height: 143.5%;

        // Span
        span {
          font-weight: 500;
          color: var(--verde50);
        }
      }

      // Texto
      p {
        width: 100%;
        margin: 16px 0px 0px 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 134.7%;
        letter-spacing: -0.015em;
        text-align: left;
        color: var(--gris);
        pointer-events: none;
      }
    }

    // Texto Botón Container
    #texto-boton-container {
      width: 100%;
      margin: 16px 0px 0px 0px;
      padding: 16px 0px 0px 0px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      border-top: 1px solid #e8f0ff;

      // Text
      p {
        width: 59%;
        margin: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 170%;
        text-align: left;
        letter-spacing: -0.015em;
        color: var(--gris);
      }

      // Button
      a {
        width: auto;
        margin: 0px 0px 0px 0px;
        padding: 12px 24px 12px 24px;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: var(--celeste90);
        border-radius: 7px;
        border: 1px solid var(--gris80);
        text-decoration: none;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 154.7%;
        text-align: center;
        color: var(--azul60);
        transition: 0.2s all;
      }

      a:hover {
        background-color: #f3f7fe;
      }
    }
  }

   // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    width: 314px;

    // Card Container
    #card-container {
    // Texto Botón Container
    #texto-boton-container {
        // Titulo
        p {
          width: 100%;
        }

        // Botón
        a {
          margin: 16px 0px 0px 0px;
        }
      }
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 324px;
  }
  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 248px;
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    width: 400px;
  }
  // 470px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile470}) {
    width: 332px;
  }

  `;
export default Container;

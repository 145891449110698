import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Link } from "react-scroll";

export default function Card({ tipo }) {
  return (
    <Container>
      {/* Image Container */}
      <div id="imagen-container">
        {/* Imagen */}
        {tipo == "beneficios" ? (
          <img
            alt="Foto de personas felices por estar seguras dentro de un auto"
            src="/home/quienesSomos/beneficios.webp"
          ></img>
        ) : (
          <img
            alt="Foto de una mujer feliz utilizando su celular"
            src="/home/quienesSomos/conocenos.webp"
          ></img>
        )}
      </div>
      {/* Data Container */}
      <div id="data-container">
        {/* Título */}
        {tipo == "beneficios" ? (
          <h3>
            La prioridad,<span> tu tranquilidad</span>
          </h3>
        ) : (
          <h3>
            Estamos para <span>vos</span>
          </h3>
        )}
        {/* Texto */}
        {tipo == "beneficios" ? (
          <p>
            Conocé a SeguroWeb. Contamos con más de 20 años de experiencia, que
            nos acompañan en la asesoría y necesidad de cada cliente.
          </p>
        ) : (
          <p>
            Nos preocupamos por brindar una atención y servicio de excelencia.
            Escribinos por el medio que te sea más conveniente y te daremos el
            mejor asesoramiento.
          </p>
        )}
        {/* Botón */}
        {tipo != "beneficios" ? (
          <Link
            to="contacto"
            smooth={true}
            spy={true}
            duration={1200}
            offset={-100}
          >
            Contactanos{" "}
            <span>
              <HiOutlineArrowLongRight />
            </span>
          </Link>
        ) : (
          <a href="/sobre-nosotros">
            Conocenos{" "}
            <span>
              <HiOutlineArrowLongRight />
            </span>
          </a>
        )}
      </div>
    </Container>
  );
}

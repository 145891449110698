import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 16px 0px 64px 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;

  // Input File Container
  #input-file-container {
    width: 188px;
    height: 188px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-radius: 10px;

    div {
      width: 100%;
      height: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;

      // Label
      label {
        width: 100%;
        height: 100%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: var(--blanco);
        border: 1.7px solid var(--gris80);
        border-radius: 10px;
        cursor: pointer;

        :hover {
          border: 1.7px solid var(--gris90);
        }

        // Ícono
        span {
          width: 100%;
          margin: 0px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          color: var(--verde50);
          font-size: 24px;
          text-align: center;
        }

        // Texto
        p {
          width: 100%;
          margin: 16px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          font-weight: 500;
          color: var(--gris);
          text-align: center;
          line-height: 13px;
        }

        // Texto formato
        p:nth-child(3) {
          margin: 10px 0px 0px 0px;
          padding: 0px 0px 0px 0px;
          font-size: 12px;
          font-weight: 400;
        }
      }

      // Input
      input {
        display: none;
      }
    }
  }

  // Archivo Container
  #archivo-container {
    width: 188px;
    height: 188px;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border: 1.7px solid var(--gris80);
    border-radius: 10px;

    // Boton Container
    #boton-container {
      width: 100%;
      height: 75px;
      margin: 0px 0px 0px 0px;
      padding: 16px 0px 16px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      // Botón
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        line-height: 10px;
        color: var(--verde50);
        transition: 0.2s all;

        // Hover
        :hover {
          opacity: 0.7;
        }
      }
    }

    // Nombre Container
    #nombre-container {
      width: 100%;
      height: 64px;
      padding: 8px 0px 8px 0px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      border-top: 1.7px solid var(--gris80);

      // Texto
      p {
        width: 80%;
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        font-weight: 500;
        color: var(--gris);
        text-align: center;
        line-height: 13px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      // Span
      span {
        width: auto;
        margin: 12px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        font-weight: 500;
        color: var(--gris);
        text-align: center;
        line-height: 13px;
        cursor: pointer;
        transition: 0.2s all;

        // Hover
        :hover {
          opacity: 0.7;
        }
      }
    }
  }
`;

export default Container;

import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 375.3px;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  border-radius: 10px;
  border: 1px solid var(--gris80);
  overflow: hidden;

  // Imagen Container
  #imagen-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: row;
    border-bottom: 1px solid var(--gris80);

    // Imagen
    img {
      width: 100%;
      height: 178px;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      object-fit: cover;
      object-position: 0px 0px;
      user-select: none;
      pointer-events: none;
    }
  }

  // Data Container
  #data-container {
    width: calc(100% - 56px);
    margin: 0px 0px 0px 0px;
    padding: 28px 28px 28px 28px;
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;

    // Título
    h3 {
      width: 100%;
      margin: 0px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-weight: 500;
      font-size: 20px;
      text-align: left;
      line-height: 145.5%;
      letter-spacing: -0.015em;

      // Span
      span {
        color: var(--azul100);
      }
    }

    // Texto
    p {
      width: 484px;
      margin: 14px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 170%;
      text-align: left;
      letter-spacing: -0.015em;
      color: var(--lila90);
    }

    // Botón
    a {
      width: auto;
      margin: 15.33px 0px 0px 0px;
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 180%;
      color: var(--verde50);
      transition: 0.2s all;
      cursor: pointer;

      // Span
      span {
        margin: 5px 0px 0px 4px;
        font-size: 20px;
        transition: 0.2s all;
      }

      :hover {
        color: var(--verde90);

        // Span
        span {
          color: var(--verde90);
          transform: translate(8px, 0px);
        }
      }
    }
  }

  // 1366px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1366}) {
    width: 310px;

    #data-container {
      h3{
        min-height: 60px;
      }
    }

  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 326px;

    #data-container {
      h3{
        min-height: 30px;
      }
      p{
        min-height: 90px;
      }
    }

  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 100%;
    margin: 28px 0px 0px 0px;
  }
`;

export default Container;

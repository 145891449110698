import React from "react";
// Estilos
import Container from "./styled";

export default function Digitalizacion() {
  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Card Container */}
        <div id="card-container">
          <div id="data-container">
            {/* Tag Container */}
            <div id="tag-container">
              {/* Tag */}
              <div>
                <p>Innovación</p>
              </div>
            </div>
            {/* Title */}
            <h3>
              <strong>Desarrollamos ideas</strong>
              <br></br>con
              <span> soluciones digitales</span>
            </h3>
            <p>
              Trabajamos para desarrollar ideas innovadoras con nuestro equipo
              IT, para que gestiones tu seguro al instante desde donde quieras y
              cuando quieras.
              <br></br>
              <br></br>
              Producimos software de alta calidad utilizando lenguajes de código
              y herramientas de última tecnología y del más alto nivel
              profesional.
            </p>
          </div>
          <div id="image-container">
            <img
              src="/home/digitalizacion.webp"
              alt="Interfaces que hacen referencia a las soluciones digitales"
            ></img>
          </div>
          <img
            src="/home/digitalizacion/curva.svg"
            id="curva-image"
            alt="Curva decorativa"
          ></img>
        </div>
      </section>
    </Container>
  );
}

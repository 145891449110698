import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 16px 0px 0px 0px;
  background-color: transparent;
  pointer-events: none !important;
  z-index: 1;

  // Max Width Container
  #max-width-container {
    width: 920px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Logo Slider Container
  .logo-slider-container {
    width: 100%;
    height: 37.2px;
    overflow: hidden;
    position: relative;
  }

  // Logo Slider
  #logo-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  // Logo
  .logo {
    width: auto;
    height: 46.4px;
    margin: 0 18px 0px 0px;
    padding: 6px 20px 6px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border: 1px solid var(--gris80);
    border-radius: 6px;
  }

  .logo img {
    width: 90%;
    pointer-events: none !important;
    user-select: none !important;
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Max Width Container
    #max-width-container {
      width: 680px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Max Width Container
    #max-width-container {
      width: 100%;
    }
  }
`;

export default Container;

const Color = require("color-js");

const Colors = {
  // Azules
  azul100: Color("#001FFF"),
  azul80: Color("#1A56FF"),
  azul60: Color("#1742E2"),
  azul40: Color("#002CCC"),
  azul20: Color("#002FB2"),
  azul0: Color("#00277F"),
  // Naranjas
  naranja100: Color("#FF9700"),
  naranja80: Color("#FFBC50"),
  naranja60: Color("#FFA733"),
  naranja40: Color("#F29500"),
  naranja20: Color("#E58800"),
  naranja0: Color("#D38200"),
  // Verdes
  verde100: Color("#0EFEC1"),
  verde90: Color("#50c7a3"),
  verde50: Color("#0DB27F"),
  // Lila
  lila100: Color("#DAE4FF"),
  lila90: Color("#95A3BF"),
  lila80: Color("#7794E5"),
  // Celestes
  celeste100: Color("#F1F6FD"),
  celeste90: Color("#F7F9FE"),
  celeste80: Color("#EDF9FF"),
  // Sombras
  sombraAzul: Color("rgba(0, 52, 253, 0.20)"),
  sombraNaranja: Color("rgba(122, 149, 238, 0.18)"),
  // Neutros
  blanco: Color("#FFFFFF"),
  negro: Color("#041726"),
  gris100: Color("#98989A"),
  gris90: Color("#EFEFEF"),
  gris80: Color("#F7F7F7"),
  gris: Color("#98989A"),
};

export default Colors;

import * as React from "react";
// Estilos
import Container from "./styled";

export default function CardSlider({ imagen, nombre, estrellas, opinion }) {
  return (
    <Container>
      {/* Usuario Container */}
      <div id="usuario-container">
        {/* Foto de perfil */}
        <img
          alt="Foto de perifl"
          src={imagen}
        ></img>
        {/* Nombre del usuario */}
        <h4>{nombre}</h4>
      </div>
      {/* Estrellas Container */}
      <div id="estrellas-container">
        <p>{estrellas}</p>
        <img alt="Estrella" src="/home/introduccion/estrella.svg"></img>
      </div>
      {/* Opinión del usuario */}
      <p id="opinion">
        "{opinion}"
      </p>
    </Container>
  );
}

import React, { useState, useEffect } from "react";
// Estilos
import Container from "./styled";
// Librerías
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
// Custom Hooks
import { useForm } from "../../../hooks/useForm";
// Form
import { contactoForm } from "../../../utils/formInitialValues";
import { contactoReglas } from "../../../utils/formRules";
// Mail
import { contactoMail } from "../../../utils/mailContent";
import { sendFormMail } from "../../../utils/sendFormMail";

export default function Conctacto() {

    // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(handleSubmit, contactoReglas, contactoForm);
  const [buttonMessage, setButtonMessage] = useState("Enviar")

  async function handleSubmit() {
    try {
      setButtonMessage("Enviando...")
      await sendFormMail(inputValues, null, contactoMail)
      setButtonMessage("¡Mensaje enviado!")
      setTimeout(() => {
        setButtonMessage("Enviar")
      }, 1000)
    } catch (error) {
      console.log(error)
    }
  }
  
  return (
    <Container name="contacto">
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Data Container */}
        <div id="data-container">
          {/* Tag Container */}
          <div id="tag-container">
            {/* Tag */}
            <div>
              <p>Contacto</p>
            </div>
          </div>
              {/* Title */}
              <h3>
                ¿Tenés <span>alguna consulta</span>?
              </h3>
              <h4>
                Escribinos o comunicate con nosotros, responderemos lo antes
                posible.
              </h4>
            <div className="text">
              Completá el siguiente formulario y envianos tu consulta, pronto
              nos comunicaremos con vos. También podes comunicarte por WhatsApp,
              correo electrónico o al 0800-220-2373.
            </div>
            <p id="adress">
              <span>
                <HiOutlineLocationMarker></HiOutlineLocationMarker>
              </span>
              Suipacha 245 1°, CABA.
            </p>
        </div>
        {/* Formulario Container */}
        <div id="formulario-container">
          {/* Formulario */}
          <form>
            {/* Input Apellido */}
            <div className="input-container" id="input-apellido-container">
              <input
                type="text"
                id="input-apellido"
                name="apellido"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.apellido}
                className={formErrors.apellido == null ? "" : "input-error"}
                placeholder="Apellido"
              ></input>
            </div>
            {/* Input Nombre */}
            <div className="input-container" id="input-nombre-container">
              <input
                type="text"
                id="input-nombre"
                name="nombre"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.nombre}
                className={formErrors.nombre == null ? "" : "input-error"}
                placeholder="Nombre"
              ></input>
            </div>
            {/* Input Email */}
            <div className="input-container" id="input-email-container">
              <input
                type="email"
                id="input-email"
                name="email"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.email}
                className={formErrors.email == null ? "" : "input-error"}
                placeholder="Email"
              ></input>
            </div>
            {/* Input Telefono */}
            <div className="input-container" id="input-telefono-container">
              <input
                type="number"
                id="input-telefono"
                name="telefono"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.telefono}
                className={formErrors.telefono == null ? "" : "input-error"}
                placeholder="Teléfono"
              ></input>
            </div>
            {/* Textarea Mensaje */}
            <div className="input-container" id="textarea-mensaje-container">
              <textarea
                type="text"
                id="textarea-mensaje"
                name="mensaje"
                onChange={handleChange}
                onFocus={handleChange}
                onBlur={handleChange}
                value={inputValues.mensaje}
                className={formErrors.mensaje == null ? "" : "input-error"}
                placeholder="Ingresá tu mensaje"
              ></textarea>
            </div>
            <button type="submit" onClick={handleValidation}>
              {buttonMessage}
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </button>
          </form>
        </div>
        <img id="background-image" alt="" src="/home/digitalizacion/curva.svg"></img>
      </section>
    </Container>
  );
}

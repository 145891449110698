import styled from "styled-components";

const Container = styled.div`
  width: 670px;
  margin: 0px 0px 0px 0px;
  padding: 40px 0px 248px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: row;

  // Título
  h4 {
    width: 100%;
    margin: 36px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  // Texto
  p {
    width: 100%;
    margin: 28px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-family: "Roboto Slab", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 190%;
    text-align: left;
    letter-spacing: -0.015em;
    color: var(--lila90);
    columns: 1;
    column-gap: 48px;
  }
`;

export default Container;

import React from "react";
// Estilos
import Container from "./styled";
// Librerías
import { Fade, Zoom } from "react-awesome-reveal";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Link } from "react-scroll";
// Componentes
import LogosArriba from "./logos-arriba";
import LogosAbajo from "./logos-abajo";
import Estadisticas from "./estadisticas";
// Utils
import useWindowDimensions from "../../../utils/useWindowDimensions";

export default function Presentacion() {
  // Estado que guarda las dimensiones de la pantalla
  const { height, width } = useWindowDimensions();

  return (
    <Container>
      {/* Section Max Width Container */}
      <section id="max-width-container">
        {/* Titulos Container */}
        <div id="titulos-container">
          {/* Encabezado */}
          <Fade delay={100} duration={800} className="encabezado-container">
            <h4 data-scroll>¡Conocé todo lo nuevo que tenemos para vos!</h4>
          </Fade>
          {/* Titulo */}
          <Zoom delay={100} duration={600} className="titulo-container">
            <h3>
              Asegurá <span>tranquilo</span> todo eso que querés, con Seguro
              <strong>Web</strong>
            </h3>
          </Zoom>
          {/* Logos */}
          <LogosArriba></LogosArriba>
          <LogosAbajo></LogosAbajo>
          {/* Botones Container */}
          <div id="botones-container">
            <Link
              to="seguros"
              smooth={true}
              spy={true}
              duration={600}
              offset={-50}
            >
              ¡Mirá nuestros seguros!
              <span>
                <HiOutlineArrowLongRight />
              </span>
            </Link>
            <Link
              to="contacto"
              smooth={true}
              spy={true}
              duration={1200}
              offset={-250}
            >
              Contactanos
            </Link>
          </div>
          {/* Estadisticas */}
          <Estadisticas></Estadisticas>
        </div>
      </section>
    </Container>
  );
}
